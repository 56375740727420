import { SelectProps } from 'antd';
import { axiosInstance } from '../api/AxiosInstance';
import config from '../config';

export const getCountries = async (): Promise<SelectProps['options']> => {
  try {
    const response = await axiosInstance.get(config.apiRoutes.address.countries, {
      timeout: 10000,
    });
    return response.data as SelectProps['options'];
  } catch (error) {
    return undefined;
  }
};

export const getCountiesByCountry = async (country: string): Promise<SelectProps['options']> => {
  try {
    const response = await axiosInstance.post(
      config.apiRoutes.address.counties,
      { value: country },
      { timeout: 60000 }
    );
    return response.data as SelectProps['options'];
  } catch (error) {
    return undefined;
  }
};

export const getCitiesByCounty = async (county: string): Promise<SelectProps['options']> => {
  try {
    const response = await axiosInstance.post(
      config.apiRoutes.address.cities,
      { value: county },
      { timeout: 60000 }
    );
    return response.data as SelectProps['options'];
  } catch (error) {
    return undefined;
  }
};
